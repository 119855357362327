.content[data-v-da807382] {
  width: 100%;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
}
.content tr[data-v-da807382] {
  padding: 0;
  margin: 0;
}
.content td[data-v-da807382] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  height: 35px;
  line-height: 35px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.right[data-v-da807382] {
  text-align: right;
  background-color: #ecf7f9;
}
.center[data-v-da807382] {
  text-align: center;
  background-color: #ecf7f9;
}
.record[data-v-da807382] {
  font-size: 14px;
  color: #17a2b8;
  margin: 10px 0;
}
.record[data-v-da807382]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
[data-v-da807382] .el-form-item__label {
  line-height: 21px !important;
}