.content[data-v-6f990bd4] {
  width: 100%;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
}
.content tr[data-v-6f990bd4] {
  padding: 0;
  margin: 0;
}
.content td[data-v-6f990bd4] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  height: 35px;
  line-height: 35px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.right[data-v-6f990bd4] {
  text-align: right;
  background-color: #ecf7f9;
}
.center[data-v-6f990bd4] {
  text-align: center;
  background-color: #ecf7f9;
}
.list-td[data-v-6f990bd4] {
  background-color: #ECF7F9;
  text-align: center;
}
.lisy-center[data-v-6f990bd4] {
  text-align: center;
}