.top_select[data-v-b9a521f6] {
  margin-bottom: 10px;
  text-align: right;
}
.el-row[data-v-b9a521f6] {
  display: flex;
  border: 1px solid #b9b9b9;
  border-bottom: none;
  border-right: none;
}
.el-row[data-v-b9a521f6]:first-child {
  border-top: 1px solid #b9b9b9;
}
.el-row[data-v-b9a521f6]:last-child {
  border-bottom: 1px solid #b9b9b9;
}
.el-col-5[data-v-b9a521f6] {
  text-align: right;
  background-color: #ecf7f9;
  padding-right: 10px;
  border-right: 1px solid #b9b9b9;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
}
.el-col-7[data-v-b9a521f6],
.el-col-19[data-v-b9a521f6] {
  padding-left: 10px;
  border-right: 1px solid #b9b9b9;
  height: 34px;
  line-height: 34px;
}