.content[data-v-3cb9ebce] {
  width: 100%;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
}
.content tr[data-v-3cb9ebce] {
  padding: 0;
  margin: 0;
}
.content td[data-v-3cb9ebce] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  height: 35px;
  line-height: 35px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.right[data-v-3cb9ebce] {
  text-align: right;
  background-color: #ecf7f9;
}
.center[data-v-3cb9ebce] {
  text-align: center;
  background-color: #ecf7f9;
}
.left_k[data-v-3cb9ebce] {
  text-indent: 2em;
  line-height: 20px;
  margin-bottom: 10px;
}