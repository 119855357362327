[data-v-553b3176] .el-radio {
  margin-right: 5px;
}
.record[data-v-553b3176] {
  font-size: 14px;
  color: #17a2b8;
  margin: 10px 0;
}
.record[data-v-553b3176]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
[data-v-553b3176] .el-form-item__label {
  line-height: 21px !important;
}